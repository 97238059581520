var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{ref:"profile_form",staticClass:"add-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;$event.preventDefault();return (() => {}).apply(null, arguments)}}},[(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_RESELLERS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.RESELLER'),"placeholder":_vm.$t('COMMON.RESELLER')}},[_c('reseller-selector',{attrs:{"reseller":_vm.role.reseller ? _vm.role.reseller.id : null,"filterable":true,"showAll":false,"allowNone":true},on:{"resellerChanged":(resellerId) => {
            _vm.role.reseller.id = resellerId;
            _vm.role.organization.id = null;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.reseller}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_ORGANIZATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.ORGANIZATION'),"placeholder":_vm.$t('COMMON.ORGANIZATION')}},[_c('organization-selector',{attrs:{"organization":_vm.role.organization ? _vm.role.organization.id : null,"filterable":true,"showAll":false,"allowNone":true,"filterReseller":_vm.role.reseller ? _vm.role.reseller.id : null},on:{"organizationChanged":(organizationId) => {
            _vm.role.organization.id = organizationId;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.organization}})],1):_vm._e(),(_vm.$currentUserCan(_vm.$permissions.PERM_VIEW_ANY_LOCATIONS))?_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.LOCATION')}`,"placeholder":_vm.$t('COMMON.LOCATION')}},[_c('locations-selector',{attrs:{"locations":_vm.role.allowedLocations,"filterable":true,"showAll":false,"multiple":true,"organization":_vm.role.organization ? _vm.role.organization.id : null},on:{"locationsChanged":(locations) => {
            _vm.role.allowedLocations = locations;
            _vm.onFormChanged();
          }}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.location}})],1):_vm._e(),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.NAME')} (*)`,"placeholder":_vm.$t('COMMON.NAME')},on:{"change":_vm.onFormChanged},model:{value:(_vm.role.name),callback:function ($$v) {_vm.$set(_vm.role, "name", $$v)},expression:"role.name"}}),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.name}})],1),_c('div',{staticClass:"form-wrapper"},[_c('base-input',{attrs:{"label":`${_vm.$t('COMMON.ROLE')}`,"placeholder":_vm.$t('COMMON.ROLE')}},[_c('role-selector',{attrs:{"filterable":true,"showAll":false},on:{"roleChanged":(roleId, role) => {
            _vm.copyRole = role;
            _vm.getCopyRole();
          }}})],1)],1),_c('div',{staticClass:"form-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit w-100",attrs:{"disabled":!_vm.copyRole,"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.copyPermissions.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("ROLES.COPY_PERMISSIONS"))+" ")])],1),_c('div',{staticClass:"form-wrapper full"},[_c('base-input',{attrs:{"label":_vm.$t('COMMON.EXCERPT'),"placeholder":_vm.$t('COMMON.EXCERPT')}},[_c('html-editor',{on:{"change":function($event){return _vm.onFormChanged()}},model:{value:(_vm.role.excerpt),callback:function ($$v) {_vm.$set(_vm.role, "excerpt", $$v)},expression:"role.excerpt"}})],1),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.excerpt}})],1),_c('div',{staticClass:"form-wrapper full permissions"},[_c('h2',{staticClass:"col-12 mt-0"},[_vm._v(" "+_vm._s(`${_vm.$t("COMMON.PERMISSIONS")} (*)`)+" ")]),_vm._l((_vm.models),function(model){return _c('div',{key:model,staticClass:"col-12 row"},[_c('h3',{staticClass:"col-12 mb-2 mt-3"},[_vm._v(" "+_vm._s(_vm.$t(`COMMON.${model}`))+" ")]),_vm._l((_vm.permissionsList.filter(
          (perm) => perm.key.includes(model) && !!_vm.permissionsModel[perm.id]
        )),function(permission){return _c('div',{key:permission.key,staticClass:"col-6"},[_c('base-checkbox',{on:{"change":_vm.onFormChanged},model:{value:(_vm.permissionsModel[permission.id].checked),callback:function ($$v) {_vm.$set(_vm.permissionsModel[permission.id], "checked", $$v)},expression:"permissionsModel[permission.id].checked"}},[_c('span',{staticClass:"form-control-label"},[_vm._v(" "+_vm._s(_vm.$t(`PERMISSIONS.${permission.key}`))+" ")])])],1)})],2)})],2),_c('validation-error',{attrs:{"errors":_vm.apiValidationErrors.permissions}}),_c('div',{staticClass:"submit-wrapper"},[_c('base-button',{staticClass:"btn btn-sm elite-submit",attrs:{"type":"button","native-type":"submit","disabled":_vm.loading}},[(_vm.loading)?_c('i',{staticClass:"fas fa-spinner fa-spin"}):_vm._e(),_vm._v(" "+_vm._s(_vm.role.id ? _vm.$t("ROLES.EDIT_ROLE") : _vm.$t("ROLES.ADD_ROLE"))+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }