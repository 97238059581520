<template>
  <div class="container-fluid">
    <div class="page-wrapper">
      <div slot="header" class="page-wrapper-header">
        <div class="row">
          <div class="col-6">
            <h3 class="mb-0">{{ $t("ROLES.ROLES_LIST") }}</h3>
          </div>
          <div class="col-6 text-right">
            <base-button
              class="elite-button add"
              icon
              size="sm"
              @click="openRoleCreateModal"
              v-if="$currentUserCan($permissions.PERM_CREATE_ROLES)"
            >
              <span class="btn-inner--icon">
                <i class="far fa-plus-circle"></i>
              </span>
              <span class="btn-inner--text">{{ $t("ROLES.ADD_ROLE") }}</span>
            </base-button>
            <notification-subscription
              v-if="$currentUserCan($permissions.PERM_VIEW_ROLES)"
              :objectType="'roles'"
              :events="{
                CREATE: $t('NOTIFICATIONS.EVENT_CREATE'),
                UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
              }"
            />
          </div>
        </div>
      </div>

      <role-list-table
        @onViewRole="openRoleViewModal"
        @onEditRole="openRoleEditModal"
        @onDeleteRole="deleteRole"
        :key="renderKey * 100"
      />

      <div
        v-if="isViewRoleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isViewRoleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 200"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'ROLE_VIEW'">
          <div class="resizable-wrapper-content-header" :class="{ system: openRole.system }">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                <span class="system-role" v-if="openRole.system"><i class="fa fa-user user-icone"></i><i class="fa fa-cog cog-icone"></i></span>{{ $t("ROLES.VIEW_ROLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <notification-subscription
                v-if="openRole"
                :objectType="'roles'"
                :objectId="openRole.id"
                :events="{
                  UPDATE: $t('NOTIFICATIONS.EVENT_UPDATE'),
                  DELETE: $t('NOTIFICATIONS.EVENT_DELETE'),
                }"
              />
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
                v-if="!openRole.system"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="edit" @click="openRoleEditModal(openRole)">
                  <i class="fal fa-edit"></i>
                  <span>{{ $t("COMMON.EDIT") }}</span>
                </button>
                <button class="delete" @click="deleteRole(openRole)">
                  <i class="fal fa-trash-alt"></i>
                  <span>{{ $t("COMMON.DELETE") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeRoleModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <view-role-page v-if="openRole" :roleId="openRole.id" />
          </div>
        </div>
      </div>

      <div
        v-if="isEditRoleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isEditRoleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 300"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'ROLE'">
          <div class="resizable-wrapper-content-header" :class="{ 'system': openRole.system }">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                <span class="system-role" v-if="openRole.system"><i class="fa fa-user user-icone"></i><i class="fa fa-cog cog-icone"></i></span>{{ $t("ROLES.EDIT_ROLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <base-dropdown
                title-classes="btn dropdown-button"
                menu-on-right
                :has-toggle="false"
              >
                <template slot="title">
                  <i class="fas fa-ellipsis-v"></i>
                </template>
                <button class="view" @click="openRoleViewModal(openRole)">
                  <i class="fal fa-expand-alt"></i>
                  <span>{{ $t("COMMON.VIEW") }}</span>
                </button>
              </base-dropdown>
              <button class="close" @click="closeRoleModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <edit-role-page
              v-if="openRole"
              :roleId="openRole.id"
              @onViewRole="openRoleViewModal"
            />
          </div>
        </div>
      </div>

      <div
        v-if="isAddRoleModalOpened"
        class="resizable-wrapper"
        v-bind:class="[isAddRoleModalOpened ? 'show' : 'hide']"
        :key="renderKey * 400"
      >
        <div class="resizable-wrapper-content" v-resizable-modal="'ROLE'">
          <div class="resizable-wrapper-content-header">
            <div class="resizable-wrapper-content-header-left">
              <h1>
                {{ $t("ROLES.ADD_ROLE") }}
              </h1>
            </div>
            <div class="resizable-wrapper-content-header-right">
              <button class="close" @click="closeRoleModal">
                <i class="fal fa-times"></i>
              </button>
            </div>
          </div>
          <div class="resizable-wrapper-content-body">
            <add-role-page @onViewRole="openRoleViewModal" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import swal from "sweetalert2";
import { Button } from "element-ui";
import requestErrorMixin from "@/mixins/request-error-mixin";
import {
  QUERY_ACTIONS_VIEW,
  QUERY_ACTIONS_EDIT,
  QUERY_ACTIONS_ADD,
} from "@/constants/common";
import NotificationSubscription from "@/components/NotificationSubscription.vue";
import RoleListTable from "./partials/RoleListTable.vue";
import EditRolePage from "./components/EditRoleComponent.vue";
import AddRolePage from "./components/AddRoleComponent.vue";
import ViewRolePage from "./components/ViewRoleComponent.vue";

export default {
  layout: "DashboardLayout",

  components: {
    RoleListTable,
    NotificationSubscription,
    EditRolePage,
    AddRolePage,
    ViewRolePage,
    [Button.name]: Button,
  },

  mixins: [requestErrorMixin],

  computed: {},

  watch: {},

  data() {
    const roleId = this.$route.query.id;
    const action = this.$route.query.action;
    let isViewRoleModalOpened = false;
    let isEditRoleModalOpened = false;
    let isAddRoleModalOpened = false;
    let openRole = null;
    if (roleId && action) {
      if (action === QUERY_ACTIONS_VIEW) {
        isViewRoleModalOpened = true;
      } else if (action === QUERY_ACTIONS_EDIT) {
        isEditRoleModalOpened = true;
      }
      openRole = { id: roleId };
    } else if (action === QUERY_ACTIONS_ADD) {
      isAddRoleModalOpened = true;
    }
    return {
      isViewRoleModalOpened: isViewRoleModalOpened,
      isEditRoleModalOpened: isEditRoleModalOpened,
      isAddRoleModalOpened: isAddRoleModalOpened,
      openRole: openRole,
      renderKey: 1,
    };
  },

  methods: {
    openRoleCreateModal() {
      this.closeRoleModal();
      this.isAddRoleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Roles",
          query: { action: QUERY_ACTIONS_ADD },
        }).href
      );
    },
    openRoleViewModal(role, reRender = false) {
      this.closeRoleModal();
      this.openRole = role;
      this.isViewRoleModalOpened = true;
      if (reRender) {
        this.renderKey++;
      }

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Roles",
          query: { id: this.openRole.id, action: QUERY_ACTIONS_VIEW },
        }).href
      );
    },
    openRoleEditModal(role) {
      this.closeRoleModal();
      this.openRole = role;
      this.isEditRoleModalOpened = true;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Roles",
          query: { id: this.openRole.id, action: QUERY_ACTIONS_EDIT },
        }).href
      );
    },
    closeRoleModal() {
      this.isAddRoleModalOpened = false;
      this.isViewRoleModalOpened = false;
      this.isEditRoleModalOpened = false;
      this.openRole = null;

      history.pushState(
        {},
        null,
        this.$router.resolve({
          name: "List Roles",
          query: {},
        }).href
      );
    },

    async deleteRole(role) {
      const confirmation = await swal.fire({
        title: this.$t("ROLES.DELETE_THIS_ROLE"),
        type: "question",
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: this.$t("COMMON.YES"),
        cancelButtonText: this.$t("COMMON.NO"),
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-warning",
      });

      try {
        if (confirmation.value === true) {
          await this.$store.dispatch("roles/destroy", role.id);
          this.renderKey++;
          this.closeRoleModal();
          this.$notify({
            type: "success",
            message: this.$t("ROLES.ROLE_DELETED"),
          });
        }
      } catch (error) {
        await this.showRequestError(error);
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },
  },
};
</script>
<style type="scoped">
  .system-role{
    margin-right: 20px;
    font-size: 18px;
  }
  .system-role .user-icone{
    z-index: 0;
  }
  .system-role .cog-icone{
    z-index: 999;
    font-size: 13px;
  }
  .resizable-wrapper-content-header.system{
    background-color: #808080 !important;
  }
</style>
